import { CSSProperties } from 'react';

export const image1: CSSProperties = {
  height: 21,
};

export const image2: CSSProperties = {
  height: 50,
  marginLeft: -10,
};
